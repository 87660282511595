:root {
  --primary-white: #fff;
  --primary-Orange: #f9ab00;
  --primary-red: #ef4035;
  --primary-redhover: #f33501;
  --primary-dark: rgba(255, 255, 255, 0);
  --primary-darkgreen: darkseagreen;
  --primary-cardGreen: #008c801f;
  --primary-Mainblue: #273f67;
  /* --primary-htmlcolor: #f8f8f8; primary Color*/
  /* --primary-htmlcolor: #aeaeae;  */
  --primary-htmlcolor: #d7d7d7;
}

html {
  background-color: var(--primary-htmlcolor);
}

.greybg {
  background-color: var(--primary-htmlcolor);
}

.top-view {
  background-color: var(--primary-htmlcolor);
  margin-bottom: -80px;
}

.logo {
  width: 140px;
  height: 80px;
  position: absolute;
  left: 50%;
  margin-left: -50px !important;
  margin-top: -30px;
  display: block;
}

/* Nav Bar */
nav {
  margin-top: 19px;
  background-color: var(--primary-htmlcolor);
}
nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}
.nav-bar-color {
  background-color: #133557;
}
nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  color: black;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  text-decoration: none;
  /* padding: 20px; */
}

.navbar-nav > li > a.actice,
.navbar-nav > li > a.active:focus {
  background-color: var(--primary-Mainblue);
  color: white;
  padding: 20px;
}

.nav-item-focused {
  background-color: var(--primary-Mainblue);
  color: white !important;
  padding: 20px;
  border-radius: 5px;
}

.navbar-nav > li > a:hover {
  background-color: var(--primary-Mainblue);
  color: white;
  padding: 20px;
  border-radius: 5px;
}

.nav-image {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

/* Nav Bar Ends */

/* welcome */
.welcomelogo {
  width: 200px;
  height: 100px;
}

.imgwelcome {
  width: 189px;
  height: 180px;
}

.imgcontainer {
  margin: auto;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
  justify-content: center;
  border-radius: 5px;
}

.welcomedatacontainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.welcome-text {
  font-weight: 600;
}

.welcomedata {
  display: flex;
  align-items: center;
}

.main-welcome-window {
  background: linear-gradient(
    90deg,
    var(--primary-htmlcolor) 70%,
    var(--primary-Mainblue) 30%
  );
  min-height: 80vh;
  margin-top: 0px;
  padding-top: 0px;
}
/* welcome till here */

/* Services */
.service-button {
  width: 200px;
  height: 200px;
  border-top-right-radius: 35px !important;
  border-bottom-left-radius: 35px !important;
  margin-top: 15px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 35px;
}
/* Services till here*/

/* Services Details */
.model-heading {
  background-color: var(--primary-Mainblue);
  border-bottom-right-radius: 50px;
}

.model-cell-numbers {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
/* Services Details till here */

/* About */
.about-img {
  padding-top: 5%;
  max-width: 100%;
  max-height: 100%;
}

.curveborder {
  border-radius: 20px;
}

.container1-fluid {
  padding: 60px 50px;
}

.business-high {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
}

.highlight-item {
  padding: 15px;
}

.about1 {
  background: linear-gradient(
    90deg,
    var(--primary-htmlcolor) 80%,
    var(--primary-Mainblue) 20%
  );
  margin-top: 5px;
}
.about2 {
  background: linear-gradient(
    90deg,
    var(--primary-Mainblue) 20%,
    var(--primary-htmlcolor) 0%
  );
  margin-top: 5px;
}
/* About till here*/

/* multiSlider Starts */
.sliderimg {
  width: 100%;
  height: 80%;
  max-height: 650px;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 700ms;
}

.sliderimg:hover {
  padding: 0px;
}
/* multiSlider till here */

/* Review Starts */
.review-page {
  border-radius: 8px;
  background-image: linear-gradient(
    to bottom right,
    var(--primary-Mainblue),
    #292b2c
  );
}

.review-text {
  width: 50%;
  margin: auto;
  padding: 10px;
}

.button-yellow {
  color: yellow !important;
}

.button-yellow:hover {
  color: black !important;
}
/* Review Starts */

/* Review Details Starts */
.serviceImage {
  width: 100%;
  border-radius: 50px;
  height: 400px;
  padding: 5px;
}

.services-list {
  list-style-type: square;
  letter-spacing: 0.0375rem;
  text-transform: uppercase;
}

.service-heading {
  letter-spacing: 0.0375rem;
  font-family: Impact, "Arial Narrow Bold", sans-serif;
}

.details-services {
  color: black;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin: 0 0 24px;
  text-indent: 30px;
}

.request-button:hover {
  background-color: var(--primary-Mainblue) !important;
}
/* Review Details Till here */

/* Contact- Page */
.contact-us-box {
  border-radius: 8px;
  background: #fafbffd4;
  padding: 30px;
}
.contact-us-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}
.contact-us-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact-data {
  border-radius: 8px;
  background: #fafbffd4;
  padding: 30px;
}
.email-data {
  font-size: 16px !important;
  font-weight: 530;
}
/* Contact- Page till here */

/* Review Details Starts */
.review-item {
  /* background-color: #f9ab00; */
  background-image: linear-gradient(
    to bottom right,
    var(--primary-Mainblue),
    #292b2c
  );
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 5px;

  min-height: 70px;
}
/* Review Details till here */

/* footer starts */
.main-foorter {
  overflow: hidden;
  background-image: linear-gradient(
    to bottom right,
    var(--primary-Mainblue),
    #292b2c
  );
  /* background: linear-gradient(
    135deg,
    #292b2ced,
    var(--primary-Mainblue)
  ) !important; */
  margin-bottom: 0;
  padding-bottom: 0;
}

.links-footer {
  text-decoration: none;
}

.links-footer:hover {
  padding: 5px;
  text-decoration: underline;
}

/* Footer Ends */

.nav-mail {
  text-decoration: none;
}

.nav-img {
  width: 40px;
}

.footer-img {
  width: 50px;
}

.nav-img-yelp {
  width: 50px;
}
.footer-img-yelp {
  width: 70px;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
}

.area-service {
  display: flex;
  gap: 10px;
}

/* Media */
@media screen and (max-width: 1050px) {
  .logo {
    position: static;
    margin-left: 50px !important;
    margin-top: 0px;
  }
  .top-view {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 768px) {
  .email-welcome {
    font-size: 20px;
  }
}
/* Media till here */
